.illustration {
  display: block;
  position: relative;
  width: auto;
  height: 100%;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.hover {
  width: 100%;
  height: 100%;
  animation: hover 5s ease-in-out infinite;
  animation-fill-mode: forwards;
}

.numberOne {
  transform: translateY(-10px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-10 4565ms ease-in-out infinite;
  animation-fill-mode: forwards;
}

.numberTwo {
  transform: translateY(-20px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-20 2950ms ease-in-out infinite;
  animation-delay: 3148ms;
  animation-fill-mode: forwards;
}

.numberThree {
  transform: translateY(-30px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-30 4630ms ease-in-out infinite;
  animation-delay: 3795ms;
  animation-fill-mode: forwards;
}

.numberFour {
  transform: translateY(-50px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-50 5698ms ease-in-out infinite;
  animation-delay: 228ms;
  animation-fill-mode: forwards;
}

.numberFive {
  transform: translateY(-60px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-60 3016ms ease-in-out infinite;
  animation-delay: 4933ms;
  animation-fill-mode: forwards;
}

.numberSix {
  transform: translateY(-70px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-70 4958ms ease-in-out infinite;
  animation-delay: 1719ms;
  animation-fill-mode: forwards;
}

.numberSeven {
  transform: translateY(-80px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-80 4047ms ease-in-out infinite;
  animation-delay: 5037ms;
  animation-fill-mode: forwards;
}

.numberEight {
  transform: translateY(-90px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-90 4925ms ease-in-out infinite;
  animation-delay: 1652ms;
  animation-fill-mode: forwards;
}

.numberNine {
  transform: translateY(-100px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-100 2492ms ease-in-out infinite;
  animation-delay: 2144ms;
  animation-fill-mode: forwards;
}

.numberTen {
  transform: translateY(-110px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-110 3796ms ease-in-out infinite;
  animation-delay: 1758ms;
  animation-fill-mode: forwards;
}

.numberEleven {
  transform: translateY(-120px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-120 5205ms ease-in-out infinite;
  animation-delay: 799ms;
  animation-fill-mode: forwards;
}

.numberTwelve {
  transform: translateY(-150px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-150 2982ms ease-in-out infinite;
  animation-delay: 998ms;
  animation-fill-mode: forwards;
}

.numberThirteen {
  transform: translateY(-160px) translateZ(0);
  opacity: 0;
  animation: fadeNumberDown-160 3816ms ease-in-out infinite;
  animation-delay: 5262ms;
  animation-fill-mode: forwards;
}

@keyframes hover {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  75% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fadeNumberDown-10 {
  0% {
    transform: translateY(-10px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(140px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-20 {
  0% {
    transform: translateY(-20px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(140px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-30 {
  0% {
    transform: translateY(-30px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(130px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-50 {
  0% {
    transform: translateY(-50px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(110px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-60 {
  0% {
    transform: translateY(-60px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(90px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-70 {
  0% {
    transform: translateY(-70px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(80px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-80 {
  0% {
    transform: translateY(-80px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(80px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-90 {
  0% {
    transform: translateY(-90px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(60px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-100 {
  0% {
    transform: translateY(-100px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(60px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-110 {
  0% {
    transform: translateY(-110px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(30px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-120 {
  0% {
    transform: translateY(-120px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(20px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-150 {
  0% {
    transform: translateY(-150px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(10px) translateZ(0);
    opacity: 0;
  }
}
@keyframes fadeNumberDown-160 {
  0% {
    transform: translateY(-160px) translateZ(0);
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(10px) translateZ(0);
    opacity: 0;
  }
}
